import styled from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const LogoImg = styled.img`
  height: 200px;
  width: auto;
  object-fit: contain;
`;

export const NumStars = styled.p`
  font-size: 18px;
  margin-left: 10px;
`;

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
