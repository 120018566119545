import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { collection, query, getDocs } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";

import { db } from "../../firebase";
import { FirebaseBusiness } from "../../types";
import { selectedBusinessAtom } from "../../state";
import { Td } from "./index.styles";

function Dashboard() {
  const setSelectedBusiness = useSetRecoilState(selectedBusinessAtom);
  const navigate = useNavigate();

  const [rows, setRows] = useState<FirebaseBusiness[]>([]);

  useEffect(() => {
    const fetchBusinesses = async () => {
      const auth = getAuth();
      if (
        process.env.REACT_APP_FIREBASE_ADMIN_EMAIL &&
        process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD
      ) {
        signInWithEmailAndPassword(
          auth,
          process.env.REACT_APP_FIREBASE_ADMIN_EMAIL,
          process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD
        )
          .then(async () => {
            // Signed in
            const q = query(collection(db, "businesses"));
            const querySnapshot = await getDocs(q);
            const tempRows: FirebaseBusiness[] = [];
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              tempRows.push(doc.data() as FirebaseBusiness);
            });
            setRows(tempRows);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(`Error ${errorCode}: ${errorMessage}`);
          });
      }
    };

    fetchBusinesses();
  }, []);

  const selectRow = (row: FirebaseBusiness): void => {
    setSelectedBusiness(row);
    navigate("/view-client");
  };

  const renderRows = (): JSX.Element[] => {
    return rows.map((row, index) => (
      <tr key={row.googlePlaceId}>
        <td>{index + 1}</td>
        <Td onClick={() => selectRow(row)}>{row.googlePlaceId}</Td>
        <td>{row.name}</td>
        <td>{row.numberOfReviews}</td>
      </tr>
    ));
  };

  return (
    <div>
      <h1>Existing Customers</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>Name</th>
            <th>Number of Reviews</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </Table>
    </div>
  );
}

export default Dashboard;
