import React from 'react';
import { StarFill } from 'react-bootstrap-icons';

import { Button } from './index.styles';

interface Props {
  hovering: boolean;
  onHover?(): void;
  onClick?(): void;
  disabled?: boolean;
  size?: number;
}

function StarButton({ hovering, onHover, onClick, disabled, size }: Props) {
  return (
    <Button onMouseEnter={() => disabled ? {} : onHover ? onHover() : {}} onClick={() => disabled ? {} : onClick ? onClick() : {}} disabled={disabled || false}>
      <StarFill color={hovering ? 'gold' : 'grey'} size={size || 60} />
    </Button>
  );
}

export default StarButton;