import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Container, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { tokenAtom } from '../../state';
import { login } from '../../actions';
import { FlexRow } from './index.styles';
import '../index.css';

function Login() {
  const setTokenAtom = useSetRecoilState(tokenAtom);

  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    let isInvalid = false;

    const setError = (func: () => void): void => {
      func();
      isInvalid = true;
    };

    if (!password.length) setError(() => setPasswordError('Password is required'));
    if (password !== process.env.REACT_APP_ADMIN_PASSWORD) setError(() => setPasswordError('Incorrect password'));
    setValidated(true);

    if (isInvalid) return;

    if (password === process.env.REACT_APP_ADMIN_PASSWORD) {
      const response = await login(password);
      if (response) {
        setTokenAtom(response);
      } else {
        setTokenAtom(null);
      }
    } else {
      toast('Invalid password', { type: 'error' });
      setTokenAtom(null);
    }
  };

  return (
    <Container>
      <h1>Admin Login</h1>
      <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
        <Form.Group className="mb-3" controlId="formYourName">
          <Form.Label>Password*</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Password"
            value={password}
            isValid={!!(password.length && password === process.env.REACT_APP_ADMIN_PASSWORD)}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && (
            <Form.Text className="error-label">
              {passwordError}
            </Form.Text>
          )}
        </Form.Group>
        <FlexRow>
          <Button
            variant="secondary"
            type="button"
            style={{ marginRight: 10 }}
            onClick={() => {
              setPassword('');
              setPasswordError('');
            }}
          >
            Cancel
          </Button>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </FlexRow>
      </Form>
    </Container>
  );
}

export default Login;