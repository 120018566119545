/**
 * Logs Admin in
 * @param password string
 * @returns Promise<string | null>
 */
export const login = async (password: string): Promise<string | null> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
      }),
    });
    if (response) {
      return await response.json();
    } else {
      console.error('Could not fetch');
      return null;
    }
  } catch (error) {
    console.error('Error', error);
    return null;
  }
};