import styled from 'styled-components';

interface ButtonProps {
  disabled: boolean;
}

export const Button = styled.button<ButtonProps>`
  background: transparent;
  border: none;
  cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
`;
