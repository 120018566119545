import { GooglePlacesResponse } from '../types';

/**
 * Searches Google Places with a search term
 * @param searchTerm string
 * @param searchCity string
 * @returns Promise<GooglePlacesResponse | boolean>
 */
export const searchPlaces = async (searchTerm: string, searchCity: string, token: string): Promise<GooglePlacesResponse | boolean> => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/places/${searchTerm}/${searchCity}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (response.ok) {
    return response.json();
  }

  return false;
};
