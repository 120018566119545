import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { doc, setDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, InputGroup, Spinner } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { toast } from "react-toastify";

import { FirebaseBusiness } from "../../types";
import { selectedBusinessAtom } from "../../state";
import { uploadFile, deleteBusinessProfile } from "../../actions";
import { db } from "../../firebase";
import ReviewCard from "../ReviewCard";
import DeleteModal from "./DeleteModal";
import StarButton from "../StarButton";
import { FlexRow, LogoImg, NumStars, SpinnerContainer } from "./index.styles";
import "../index.css";

function NewClient() {
  const selectedBusiness = useRecoilValue(selectedBusinessAtom);
  const navigate = useNavigate();

  const [name, setName] = useState<string>(selectedBusiness?.name || "");
  const [nameError, setNameError] = useState<string>("");
  const [logoImg, setLogoImg] = useState<string>("");
  const [logoImgError, setLogoImgError] = useState<string>("");
  const [logoImgFile, setLogoImgFile] = useState<File | null>(null);
  const [logoImgDataUri, setLogoImgDataUri] = useState<string>(
    selectedBusiness?.logoImg || ""
  );
  const [logoImgUploading, setLogoImgUploading] = useState<boolean>(false);
  const [logoImgUrl, setLogoImgUrl] = useState<string | null>(
    selectedBusiness?.logoImg || null
  );
  const [email, setEmail] = useState<string>(
    selectedBusiness?.sendFeedbackEmail || ""
  );
  const [emailError, setEmailError] = useState<string>("");
  const [validated, setValidated] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const close = (): void => {
    navigate(-1);
  };

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();
    const form = event ? event.currentTarget : null;
    if (event && form && form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    let isInvalid = false;

    const setError = (func: () => void): void => {
      func();
      isInvalid = true;
    };

    if (!name.length) setError(() => setNameError("Name is required"));
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      setError(() => setEmailError("Must be a valid email address"));
    }
    setValidated(true);

    if (isInvalid) return;

    // Add data to firebase
    if (selectedBusiness) {
      try {
        const newObj: FirebaseBusiness = {
          name,
          logoImg: logoImgUrl || "",
          googlePlaceId: selectedBusiness.googlePlaceId,
          numberOfReviews: 0,
          feedbackReviews: [],
          googleReviewsClicked: {
            4: 0,
            5: 0,
          },
          description: "",
          sendFeedbackEmail: email,
        };
        if (
          process.env.REACT_APP_FIREBASE_ADMIN_EMAIL &&
          process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD
        ) {
          setLoading(true);
          const auth = getAuth();
          signInWithEmailAndPassword(
            auth,
            process.env.REACT_APP_FIREBASE_ADMIN_EMAIL,
            process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD
          )
            .then(async () => {
              // Signed in
              await setDoc(
                doc(db, "businesses", selectedBusiness.googlePlaceId),
                newObj
              );
              setLoading(false);
              console.log(
                "Document written with ID: ",
                selectedBusiness.googlePlaceId
              );
              toast("Updated details successfully", { type: "success" });
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.error(`Error ${errorCode}: ${errorMessage}`);
              setLoading(false);
              return false;
            });
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  const handleUploadFile = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (logoImgFile && selectedBusiness) {
      setLogoImgUploading(true);
      const uploadResponse: string | null = await uploadFile(
        selectedBusiness.googlePlaceId,
        logoImgFile
      );
      setLogoImgUploading(false);
      if (uploadResponse) {
        setLogoImgUrl(uploadResponse);
        toast("File uploaded successfully", { type: "success" });
      } else {
        setLogoImgUrl(null);
        toast("Could not upload file", { type: "error" });
      }
    } else {
      toast("Cannot upload file without selecting", { type: "error" });
    }
  };

  const dataToFileUri = (file: File | null) =>
    new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event && event.target && event.target.result) {
            resolve(event.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    });

  const onFileChange = (file: File | null) => {
    if (!file) {
      setLogoImgDataUri("");
      return;
    }

    setLogoImgFile(file);

    dataToFileUri(file).then((dataUri) => {
      setLogoImgDataUri(dataUri as string);
    });
  };

  const clearFile = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setLogoImg("");
    setLogoImgError("");
    setLogoImgDataUri("");
    setLogoImgUploading(false);
  };

  const renderFeedbackReviews = (): JSX.Element => {
    if (!selectedBusiness) return <></>;
    const { feedbackReviews } = selectedBusiness;

    const feedbackDivs = feedbackReviews.map((feedbackReview, index) => (
      <ReviewCard
        key={`feedback-review-${index}`}
        uniqueKey={`feedback-review-${index}`}
        review={feedbackReview}
      />
    ));

    return <>{feedbackDivs}</>;
  };

  const copyTextToClipboard = async () => {
    const url = `${window.location.protocol}//${window.location.host}/business/${selectedBusiness?.googlePlaceId}`;
    if (selectedBusiness) {
      try {
        await navigator.clipboard.writeText(url);
        toast("Copied to clipboard", { type: "success" });
      } catch (error) {
        console.error("Copy URL error", error);
        toast("Could not copy URL to clipboard", { type: "error" });
      }
    }
  };

  const deleteBusiness = async () => {
    if (selectedBusiness) {
      setLoading(true);
      const success = await deleteBusinessProfile(
        selectedBusiness?.googlePlaceId
      );
      setLoading(false);
      if (success) {
        toast("Deleted successfully", { type: "success" });
        navigate("/");
      } else {
        toast("Could not delete at this time", { type: "error" });
      }
    } else {
      toast("Could not delete at this time", { type: "error" });
    }
  };

  const renderStars = (num: number): JSX.Element[] => {
    const arr: JSX.Element[] = [];
    for (let i = 0; i < num; i++) {
      arr.push(
        <StarButton
          key={`${num}-stars-${i}`}
          hovering={true}
          disabled
          size={20}
        />
      );
    }
    return arr;
  };

  return (
    <Container>
      {loading && (
        <SpinnerContainer>
          <Spinner animation="border" />
        </SpinnerContainer>
      )}
      {deleteOpen && (
        <DeleteModal
          showModal={deleteOpen}
          close={() => setDeleteOpen(false)}
          confirm={() => deleteBusiness()}
        />
      )}
      {selectedBusiness && <h1>{selectedBusiness.name}</h1>}
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        style={{ textAlign: "left" }}
      >
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Business Name*</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Business Name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && (
            <Form.Text className="error-label">{nameError}</Form.Text>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Google Place ID</Form.Label>
          <Form.Control
            disabled
            type="text"
            placeholder="Google Place ID"
            value={selectedBusiness?.googlePlaceId || ""}
          />
        </Form.Group>

        <Form.Group controlId="logo" className="mb-3">
          <Form.Label>Logo</Form.Label>
          <InputGroup>
            <Form.Control
              disabled={logoImgUploading}
              type="file"
              value={logoImg}
              onChange={(event) => {
                const target = event.target as HTMLInputElement;
                setLogoImg(target.value);
                if (target && target.files && target.files[0]) {
                  onFileChange(target.files[0]);
                }
              }}
            />
            {logoImg && (
              <Button type="button" variant="secondary" onClick={clearFile}>
                <X />
              </Button>
            )}
            <Button type="button" variant="primary" onClick={handleUploadFile}>
              {logoImgUploading ? <Spinner animation="border" /> : "Upload"}
            </Button>
          </InputGroup>
          {logoImgError && (
            <Form.Text className="error-label">{logoImgError}</Form.Text>
          )}
        </Form.Group>

        {(logoImgUrl || logoImgDataUri) && (
          <LogoImg
            src={logoImgUrl || logoImgDataUri}
            alt={`${
              selectedBusiness ? selectedBusiness.name : "Business"
            }'s logo`}
          />
        )}

        <Form.Group
          className="mb-3"
          controlId="email"
          style={{ marginTop: 20 }}
        >
          <Form.Label>Send Feedback to Email*</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Send Feedback to Email*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && (
            <Form.Text className="error-label">{emailError}</Form.Text>
          )}
        </Form.Group>

        <FlexRow style={{ marginTop: 30, marginBottom: 30 }}>
          <Button
            variant="secondary"
            type="button"
            style={{ marginRight: 10 }}
            onClick={close}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>

          <Button
            variant="success"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              copyTextToClipboard();
            }}
          >
            Share
          </Button>

          <Button
            variant="danger"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setDeleteOpen(true);
            }}
          >
            Delete
          </Button>
        </FlexRow>
      </Form>

      <h2>Google Reviews Clicked</h2>
      {/* 5 Stars */}
      <FlexRow
        style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
      >
        {renderStars(5)}
        <NumStars>{selectedBusiness?.googleReviewsClicked["5"]}</NumStars>
      </FlexRow>
      {/* 4 Stars */}
      <FlexRow
        style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
      >
        {renderStars(4)}
        <NumStars>{selectedBusiness?.googleReviewsClicked["4"]}</NumStars>
      </FlexRow>
      {/* Track in Firebase */}
      <h2 style={{ marginTop: 30 }}>Feedback Reviews</h2>
      {selectedBusiness?.feedbackReviews.length ? (
        renderFeedbackReviews()
      ) : (
        <p>No Feedback received yet</p>
      )}
    </Container>
  );
}

export default NewClient;
