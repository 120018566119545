import React from 'react';

import { Img } from './index.styles';

interface Props {
  src: string;
  alt: string;
}

function Logo({ src, alt }: Props) {
  return (
    <Img src={src} alt={alt} />
  );
}

export default Logo;