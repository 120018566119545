import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Card, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import { GooglePlacesReview } from "../../types";
import {
  fetchBusinessProfile,
  fetchGoogleReviews,
  incrementGoogleReviewsCount,
} from "../../actions";
import StarButton from "../StarButton";
import Logo from "../Logo";
import Popup from "../Popup";
import ReviewCard from "../ReviewCard";
import { Stars } from "./index.styles";

function Main() {
  const params = useParams();
  const { placeId } = params;

  const [loading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [reviews, setReviews] = useState<GooglePlacesReview[]>([]);
  const [totalRating, setTotalRating] = useState<number>(0);
  const [numReviews, setNumReviews] = useState<number>(0);
  const [pageUrl, setPageUrl] = useState<string>("");
  const [hoverRating, setHoverRating] = useState<number>(0);
  const [selectedRating, setSelectedRating] = useState<number>(0);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [logoImg, setLogoImg] = useState<string | null>(null);
  const [sendToEmail, setSendToEmail] = useState<string | null>(null);

  useEffect(() => {
    const getReviews = async () => {
      if (placeId) {
        const firebaseResponse = await fetchBusinessProfile(placeId);
        if (firebaseResponse) {
          if (firebaseResponse.logoImg) {
            setLogoImg(firebaseResponse.logoImg);
          }
          if (firebaseResponse.sendFeedbackEmail) {
            setSendToEmail(firebaseResponse.sendFeedbackEmail);
          }
        } else {
          toast("Could not fetch profile from Firebase", { type: "error" });
        }

        const googlePlacesResponse = await fetchGoogleReviews(placeId);
        if (
          typeof googlePlacesResponse !== "boolean" &&
          googlePlacesResponse.result
        ) {
          const { name, reviews, rating, user_ratings_total, url } =
            googlePlacesResponse.result;
          setName(name);
          setReviews(reviews);
          setTotalRating(rating);
          setNumReviews(user_ratings_total);
          setPageUrl(url);
          setLoading(false);
        } else {
          toast("Could not fetch reviews", { type: "error" });
          setLoading(false);
        }
      }
    };

    if (placeId) getReviews();
  }, [placeId]);

  const submitRating = async (stars: number) => {
    if (stars < 4) {
      setSelectedRating(stars);
      setShowPopup(true);
    } else if (placeId) {
      window.open(
        `http://search.google.com/local/writereview?placeid=${placeId}`
      );
      // Uptick Google Reviews
      await incrementGoogleReviewsCount(placeId, stars);
    }
  };

  const renderTotalRating = (): JSX.Element => {
    let totalRatingStars: JSX.Element[] = [];

    for (let i = 0; i < totalRating; i++) {
      totalRatingStars.push(
        <StarButton
          key={`total-rating-star-${i}`}
          disabled
          hovering
          size={40}
        />
      );
    }

    return <Stars>{totalRatingStars}</Stars>;
  };

  const renderReviews = (): JSX.Element[] => {
    return reviews.map((review, index) => (
      <ReviewCard
        key={`review-${index}`}
        uniqueKey={`review-${index}`}
        review={review}
      />
    ));
  };

  return (
    <Container>
      {loading ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Popup
            show={showPopup}
            placeId={placeId}
            placeName={name}
            rating={selectedRating}
            handleClose={() => setShowPopup(false)}
            sendToEmail={sendToEmail || ""}
          />
          {logoImg && <Logo src={logoImg} alt="Sample logo" />}
          <p>Help us. Help others. You&apos;re invited to review:</p>
          {name && <h1>{name}</h1>}
          <Stars>
            <StarButton
              onHover={() => setHoverRating(1)}
              hovering={hoverRating >= 1}
              onClick={() => submitRating(1)}
            />
            <StarButton
              onHover={() => setHoverRating(2)}
              hovering={hoverRating >= 2}
              onClick={() => submitRating(2)}
            />
            <StarButton
              onHover={() => setHoverRating(3)}
              hovering={hoverRating >= 3}
              onClick={() => submitRating(3)}
            />
            <StarButton
              onHover={() => setHoverRating(4)}
              hovering={hoverRating >= 4}
              onClick={() => submitRating(4)}
            />
            <StarButton
              onHover={() => setHoverRating(5)}
              hovering={hoverRating >= 5}
              onClick={() => submitRating(5)}
            />
          </Stars>
          <p style={{ marginTop: 20 }}>Select a Rating</p>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              padding: 20,
              borderRadius: 0,
            }}
          >
            <h2>Our Rating</h2>
            <p>
              {name} is rated {totalRating.toFixed(1)} out of 5.0 based on{" "}
              {numReviews} reviews
            </p>
            {renderTotalRating()}
          </Card>
          {renderReviews()}
          {reviews.length === 5 && (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <p>Showing 5 of {numReviews}</p>
              {pageUrl && name && (
                <a
                  href={pageUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  See More
                </a>
              )}
            </div>
          )}
        </>
      )}
    </Container>
  );
}

export default Main;
