import React from "react";
import { Modal } from "react-bootstrap";

import FeedbackForm from "./FeedbackForm";

interface Props {
  show: boolean;
  placeId: string | undefined;
  placeName: string;
  rating: number;
  handleClose(): void;
  sendToEmail: string;
}

function Popup({
  show,
  placeId,
  placeName,
  rating,
  handleClose,
  sendToEmail,
}: Props) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Leave Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 style={{ textAlign: "center" }}>{placeName}</h2>
        <p>
          I strive for 100% customer satisfaction and obviously, I fell short.
        </p>
        <p>
          Please share with my tell me your concerns so I can address them
          immediately.
        </p>
        <FeedbackForm
          placeId={placeId}
          rating={rating}
          close={handleClose}
          businessName={placeName}
          sendToEmail={sendToEmail}
        />
      </Modal.Body>
    </Modal>
  );
}

export default Popup;
