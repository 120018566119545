/**
 * Sends Feedback Email via /send-feedback-email POST route from API
 * @param businessName string
 * @param numStars number
 * @param feedback string
 * @param sendToEmail string
 * @param authorName string
 * @param authorEmail string
 * @param authorPhone string
 * @param dateTime string
 * @returns Promise<any>
 */
export const sendFeedbackEmail = async (businessName: string, numStars: number, feedback: string, sendToEmail: string, authorName: string, authorEmail: string, authorPhone: string, dateTime: string): Promise<any> => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/send-feedback-email`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      businessName,
      numStars,
      feedback,
      sendToEmail,
      authorName,
      authorEmail,
      authorPhone,
      dateTime,
    }),
  });

  return response;
};