import { doc, setDoc, getDoc, deleteDoc, DocumentData } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';

import { db } from '../firebase';
import { FeedbackReview } from '../types';

/**
 * Increments the review count for a business
 * @param placeId string
 * @returns Promise<boolean>
 */
export const incrementGoogleReviewsCount = async (placeId: string, rating: number): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      const auth = getAuth();
      if (process.env.REACT_APP_FIREBASE_ADMIN_EMAIL && process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD) {
        signInWithEmailAndPassword(auth, process.env.REACT_APP_FIREBASE_ADMIN_EMAIL, process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD)
          .then(async (firebaseUser) => {
            // Signed in 
            const docRef = doc(db, 'businesses', placeId);
            const docSnap = await getDoc(docRef);
  
            if (docSnap.exists()) {
              const existingObj = docSnap.data();
              if (existingObj) {
                const newObj = {
                  ...existingObj,
                  googleReviewsClicked: {
                    ...existingObj.googleReviewsClicked,
                    [rating]: existingObj.googleReviewsClicked[rating] + 1,
                  },
                  numberOfReviews: existingObj.numberOfReviews + 1,
                };
                await setDoc(doc(db, 'businesses', placeId), newObj);
                console.log('Document overwritten with ID: ', placeId);
                toast('Redirected to Google', { type: 'success' });
                resolve(true);
              } else {
                console.error('Could not add +1 Google Review', { type: 'error' });
                reject(false);
              }
            } else {
              console.error('No such document!');
              console.error('Could not add +1 Google Review', { type: 'error' });
              reject(false);
            }
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(`Error ${errorCode}: ${errorMessage}`);
            reject(false);
          });
      }
    } catch (e) {
      console.error('Error overwriting document: ', e);
      console.error('Could not add +1 Google Review', { type: 'error' });
      reject(false);
    }
  });
};

/**
 * Adds a new Feedback Review item for a saved place, also increments count
 * @param placeId string
 * @param newReview FeedbackReview
 * @returns Promise<string>
 */
export const addFeedback = async (placeId: string, newReview: FeedbackReview): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const auth = getAuth();
      if (process.env.REACT_APP_FIREBASE_ADMIN_EMAIL && process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD) {
        signInWithEmailAndPassword(auth, process.env.REACT_APP_FIREBASE_ADMIN_EMAIL, process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD)
        .then(async () => {
          // Signed in 
          const docRef = doc(db, 'businesses', placeId);
          const docSnap = await getDoc(docRef);
  
          if (docSnap.exists()) {
            const existingObj = docSnap.data();
            if (existingObj) {
              const newObj = {
                ...existingObj,
                numberOfReviews: existingObj.numberOfReviews + 1,
                feedbackReviews: [
                  ...existingObj.feedbackReviews,
                  newReview,
                ],
              };
              await setDoc(doc(db, 'businesses', placeId), newObj);
              console.log('Document overwritten with ID: ', placeId);
              toast('Submitted Feedback', { type: 'success' });
              resolve('Submited Feedback');
            } else {
              toast('Could not add review', { type: 'error' });
              reject('Could not add review');
            }
          } else {
            console.error('No such document!');
            toast('Could not add review', { type: 'error' });
            reject('No such document!');
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`Error ${errorCode}: ${errorMessage}`);
          toast(`Error: ${errorMessage}`);
          reject(errorMessage);
        });
      }
    } catch (e) {
      console.error('Error overwriting document: ', e);
      toast('Could not add review', { type: 'error' });
      reject('Could not add review');
    }
  });
};

/**
 * Uploads a file to Firestore
 * @param file Blob | Uint8Array | ArrayBuffer
 */
export const uploadFile = async (placeId: string, file: Blob | Uint8Array | ArrayBuffer): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    const storageRef = ref(storage, placeId);
    const auth = getAuth();
    if (process.env.REACT_APP_FIREBASE_ADMIN_EMAIL && process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD) {
      signInWithEmailAndPassword(auth, process.env.REACT_APP_FIREBASE_ADMIN_EMAIL, process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD)
      .then(async () => {
        // Signed in 
        console.log('LOGGED IN!');
        uploadBytesResumable(storageRef, file).then((uploadTask) => {
          console.log('File uploaded successfully');
          toast('File uploaded successfully', { type: 'success' });
      
          // Register three observers:
          // 1. 'state_changed' observer, called any time the state changes
          // 2. Error observer, called on failure
          // 3. Completion observer, called on successful completion
      
          uploadTask.task.on('state_changed', 
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          }, 
          (error) => {
            // Handle unsuccessful uploads
            console.error('Upload unsuccessful', error);
            reject(error);
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.task.snapshot.ref).then((downloadURL) => {
              console.log('File available at', downloadURL);
              resolve(downloadURL);
            }).catch((err) => {
              console.error('get download url error', err);
              reject(err);
            });
          }
        );
        }).catch((error) => {
          console.error('upload byres resumable error', error);
          reject(error);
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(`Error ${errorCode}: ${errorMessage}`);
        reject(error);
      });
    }
  });  
};

/**
 * Fetches the object stored in Firebase based on placeId
 * @param placeId string
 * @returns Promise<DocumentData | null>
 */
 export const fetchBusinessProfile = async (placeId: string): Promise<DocumentData | null> => {
  return new Promise((resolve, reject) => {
    try {
      const auth = getAuth();
      if (process.env.REACT_APP_FIREBASE_ADMIN_EMAIL && process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD) {
        signInWithEmailAndPassword(auth, process.env.REACT_APP_FIREBASE_ADMIN_EMAIL, process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD)
        .then(async () => {
          // Signed in 
          const docRef = doc(db, 'businesses', placeId);
          const docSnap = await getDoc(docRef);
  
          if (docSnap.exists()) {
            resolve(docSnap.data());
          } else {
            console.error('Error: ', 'Document does not exist in Firebase');
            reject(null);
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`Error ${errorCode}: ${errorMessage}`);
          reject(null);
        });
      } else {
        console.error('Cannot log in because no env variables');
        reject(null);
      }
    } catch (error) {
      console.error('Error', error);
      reject(null);
    }
  });
};

/**
 * Deletes a business profile in firebase
 * @param placeId string
 * @returns Promise<boolean>
 */
export const deleteBusinessProfile = async (placeId: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      const auth = getAuth();
      if (process.env.REACT_APP_FIREBASE_ADMIN_EMAIL && process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD) {
        signInWithEmailAndPassword(auth, process.env.REACT_APP_FIREBASE_ADMIN_EMAIL, process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD)
        .then(async () => {
          // Signed in 
          await deleteDoc(doc(db, 'businesses', placeId));
          resolve(true);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`Error ${errorCode}: ${errorMessage}`);
          reject(false);
        });
      }
    } catch (error) {
      console.error('Error deleting document', error);
      reject(false);
    }
  });
};