import React from "react";
import { useRecoilValue } from "recoil";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { tokenAtom } from "./state";
import "./App.css";
import { TopNav, Main, Admin, NewClient, Home, ViewClient } from "./components";

function App() {
  const token = useRecoilValue(tokenAtom);

  return (
    <BrowserRouter>
      <div className="App">
        {token && <TopNav />}
        <Routes>
          <Route path="/business/:placeId" element={<Main />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/new-client" element={<NewClient />} />
          <Route path="/view-client" element={<ViewClient />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
