import React from 'react';
import { Container } from 'react-bootstrap';

function Home() {
  return (
    <Container style={{ marginTop: 20 }}>
      <h1>720 Review System</h1>
    </Container>
  );
}

export default Home;