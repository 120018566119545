import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Container } from 'react-bootstrap';

import { tokenAtom } from '../../state';
import Login from '../Login';
import Default from './Default';
import NewClient from '../NewClient';
import Dashboard from '../Dashboard';

function Admin() {
  const token = useRecoilValue(tokenAtom);

  const [viewState, setViewState] = useState<'default' | 'new-client' | 'businesses'>('default');

  const renderViewState = (): JSX.Element => {
    switch (viewState) {
      case 'default':
        return <Default changeAdminState={(newState: 'new-client' | 'businesses') => setViewState(newState)} />;
      case 'new-client':
        return <NewClient />;
      case 'businesses':
        return <Dashboard />;
      default:
        return <Default changeAdminState={(newState: 'new-client' | 'businesses') => setViewState(newState)} />;
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      {token ? renderViewState() : <Login />}
    </Container>
  );
}

export default Admin;