import React, { useState, useRef } from "react";
import { Form, Button, Overlay, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { format } from "date-fns";

import { addFeedback, sendFeedbackEmail } from "../../../actions";
import { FlexRow, LogoImg } from "./index.styles";
import googleLogo from "../../../images/google.png";
import "../../index.css";

interface Props {
  placeId: string | undefined;
  rating: number;
  close(): void;
  businessName: string;
  sendToEmail: string;
}

function FeedbackForm({
  placeId,
  rating,
  close,
  businessName,
  sendToEmail,
}: Props) {
  const target = useRef(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [validated, setValidated] = useState<boolean>(false);

  const clearData = (): void => {
    setName("");
    setNameError("");
    setPhone("");
    setPhoneError("");
    setEmail("");
    setEmailError("");
    setMessage("");
    setValidated(false);
  };

  const handleSubmit = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    let isInvalid = false;

    const setError = (func: () => void): void => {
      func();
      isInvalid = true;
    };

    if (!name.length) setError(() => setNameError("Name is required"));
    if (!/^\+*(\d{3})*[0-9,-]{8,}/.test(phone))
      setError(() => setPhoneError("Phone number is invalid"));
    if (!email.length) setError(() => setEmailError("Email is required"));
    if (email.length && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))
      setError(() => setEmailError("Email is invalid"));

    setValidated(true);

    if (isInvalid) return;

    // Add to Firebase
    if (placeId) {
      await addFeedback(placeId, {
        author_name: name,
        phone,
        email,
        text: message,
        rating,
        time: Date.now(),
        relative_time_description: null,
      });
      if (sendToEmail) {
        await sendFeedbackEmail(
          businessName,
          rating,
          message,
          sendToEmail,
          name,
          email,
          phone,
          format(Date.now(), "MM/dd/yyyy HH:mm:ss")
        );
      }
      clearData();
      close();
    } else {
      toast("Cannot add review right now", { type: "error" });
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formYourName">
        <Form.Label>Your Name*</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Your Name*"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {nameError && (
          <Form.Text className="error-label">{nameError}</Form.Text>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formPhone">
        <Form.Label>Phone</Form.Label>
        <Form.Control
          type="number"
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        {phoneError && (
          <Form.Text className="error-label">{phoneError}</Form.Text>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label>Email*</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Email*"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError && (
          <Form.Text className="error-label">{emailError}</Form.Text>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formMessage">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Form.Group>

      <FlexRow>
        <Button
          variant="secondary"
          type="button"
          style={{ marginRight: 10 }}
          onClick={close}
        >
          Cancel
        </Button>

        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Send Message
        </Button>
      </FlexRow>

      <FlexRow>
        <Button
          ref={target}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          type="button"
          variant="link"
          onClick={() => window.open("https://www.google.com", "_blank")}
        >
          <LogoImg src={googleLogo} alt="Google's logo" />
        </Button>
        <Overlay target={target.current} show={showTooltip} placement="right">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Go to Google
            </Tooltip>
          )}
        </Overlay>
      </FlexRow>
    </Form>
  );
}

export default FeedbackForm;
