import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface Props {
  showModal: boolean;
  close(): void;
  confirm(): void;
}

function DeleteModal({ showModal, close, confirm }: Props) {
  return (
    <Modal show={showModal} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Delete?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to delete this profile?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={(e) => {
          e.preventDefault();
          close();
        }}>Close</Button>
        <Button variant="danger" onClick={(e) => {
          e.preventDefault();
          confirm();
          close();
        }}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;