import React from 'react';
import { Card } from 'react-bootstrap';
import { Google } from 'react-bootstrap-icons';
import { format } from 'date-fns';

import { FeedbackReview, GooglePlacesReview } from '../../types';
import StarButton from '../StarButton';
import { FlexRow } from './index.styles';

interface Props {
  uniqueKey: string;
  review: GooglePlacesReview | FeedbackReview;
}

function ReviewCard({ uniqueKey, review }: Props) {
  const { author_name, rating, relative_time_description, text, time } = review;

  const renderRating = (): JSX.Element => {
    let stars: JSX.Element[] = [];

    for (let i = 0; i < rating; i++) {
      stars.push(<StarButton key={`${uniqueKey}-star-${i}`} hovering disabled size={20} />)
    }

    return <FlexRow>{stars}</FlexRow>;
  };

  return (
    <Card key={uniqueKey || `review-${Math.random() * 1000}`} style={{ padding: 20, textAlign: 'left' }}>
      <FlexRow style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        {relative_time_description ? (
          <p style={{ marginRight: 10 }}>{`${relative_time_description[0].toUpperCase()}${relative_time_description.substring(1)}`}</p>
        ) : (
          <p style={{ marginRight: 10 }}>{format(time, 'MM/dd/yyyy HH:mm')}</p>
        )}
        {renderRating()}
      </FlexRow>
      {text ? <p>"{text}"</p> : <p />}
      <FlexRow style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <Google />
        <span style={{ marginLeft: 10, marginRight: 10 }}>{author_name}</span>
      </FlexRow>
    </Card>
  );
}

export default ReviewCard;