import React from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { tokenAtom } from '../../state';
import { FlexRow } from './index.styles';

interface Props {
  changeAdminState(newState: 'new-client' | 'businesses'): void;
}

function Default({ changeAdminState }: Props) {
  const navigate = useNavigate();
  const setToken = useSetRecoilState(tokenAtom);

  return (
    <div>
      <FlexRow>
        <Button
          type="button"
          variant="success"
          onClick={(e) => {
            e.preventDefault();
            changeAdminState('new-client');
          }}
        >
          New Client
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            changeAdminState('businesses');
          }}
        >
          Dashboard
        </Button>
        <Button
          type="button"
          variant="danger"
          onClick={(e) => {
            e.preventDefault();
            setToken(null);
            navigate('/');
          }}
        >
          Logout
        </Button>
      </FlexRow>
    </div>
  );
}

export default Default;