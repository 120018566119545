import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { doc, setDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Dropdown,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { toast } from "react-toastify";

import { GooglePlacesResult, FirebaseBusiness } from "../../types";
import { tokenAtom } from "../../state";
import { searchPlaces, uploadFile } from "../../actions";
import { db } from "../../firebase";
import { FlexRow, LogoImg } from "./index.styles";
import "../index.css";

function NewClient() {
  const navigate = useNavigate();
  const token = useRecoilValue(tokenAtom);

  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<GooglePlacesResult[]>([]);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] =
    useState<GooglePlacesResult | null>(null);
  const [placeError, setPlaceError] = useState<string>("");
  const [logoImg, setLogoImg] = useState<string>("");
  const [logoImgError, setLogoImgError] = useState<string>("");
  const [logoImgFile, setLogoImgFile] = useState<File | null>(null);
  const [logoImgDataUri, setLogoImgDataUri] = useState<string>("");
  const [logoImgUploading, setLogoImgUploading] = useState<boolean>(false);
  const [logoImgUrl, setLogoImgUrl] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const [validated, setValidated] = useState<boolean>(false);

  const close = (): void => {
    setName("");
    setNameError("");
    setSelectedResult(null);
    setPlaceError("");
    setLogoImg("");
    setLogoImgError("");
    setValidated(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    let isInvalid = false;

    const setError = (func: () => void): void => {
      func();
      isInvalid = true;
    };

    if (!name.length) setError(() => setNameError("Name is required"));
    if (!selectedResult)
      setError(() => setPlaceError("You must select a Google Place"));
    if (!logoImg.length)
      setError(() => setLogoImgError("Logo image is required"));
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      setError(() => setEmailError("Must be a valid email address"));
    }
    setValidated(true);

    if (isInvalid) return;

    // Add data to firebase
    if (selectedResult) {
      try {
        const newObj: FirebaseBusiness = {
          name,
          logoImg: logoImgUrl || "",
          googlePlaceId: selectedResult.place_id,
          numberOfReviews: 0,
          feedbackReviews: [],
          googleReviewsClicked: {
            5: 0,
            4: 0,
          },
          description: "",
          sendFeedbackEmail: email,
        };
        const auth = getAuth();
        if (
          process.env.REACT_APP_FIREBASE_ADMIN_EMAIL &&
          process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD
        ) {
          signInWithEmailAndPassword(
            auth,
            process.env.REACT_APP_FIREBASE_ADMIN_EMAIL,
            process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD
          )
            .then(async () => {
              // Signed in
              await setDoc(
                doc(db, "businesses", selectedResult.place_id),
                newObj
              );
              console.log(
                "Document written with ID: ",
                selectedResult.place_id
              );
              toast("Added client successfully", { type: "success" });
              navigate(`/business/${selectedResult.place_id}`);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.error(`Error ${errorCode}: ${errorMessage}`);
              return false;
            });
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  const searchForPlaces = async () => {
    if (token) {
      const response = await searchPlaces(searchTerm, searchCity, token);
      if (typeof response !== "boolean") {
        if (response.candidates) {
          setSearchResults(response.candidates);
          setSearchActive(true);
        }
      } else {
        toast("Could not fetch search results", { type: "error" });
      }
    } else {
      toast("You are not authorized to search", { type: "error" });
    }
  };

  const pickSearchResult = (searchResult: GooglePlacesResult): void => {
    setSelectedResult(searchResult);
  };

  const handleUploadFile = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (logoImgFile && selectedResult) {
      setLogoImgUploading(true);
      const uploadResponse: string | null = await uploadFile(
        selectedResult.place_id,
        logoImgFile
      );
      setLogoImgUploading(false);
      if (uploadResponse) {
        setLogoImgUrl(uploadResponse);
        toast("File uploaded successfully", { type: "success" });
      } else {
        setLogoImgUrl(null);
        toast("Could not upload file", { type: "error" });
      }
    } else {
      toast("Cannot upload file without selecting", { type: "error" });
    }
  };

  const dataToFileUri = (file: File | null) =>
    new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event && event.target && event.target.result) {
            resolve(event.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    });

  const onFileChange = (file: File | null) => {
    if (!file) {
      setLogoImgDataUri("");
      return;
    }

    setLogoImgFile(file);

    dataToFileUri(file).then((dataUri) => {
      setLogoImgDataUri(dataUri as string);
    });
  };

  const clearFile = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setLogoImg("");
    setLogoImgError("");
    setLogoImgDataUri("");
    setLogoImgUploading(false);
  };

  const renderSearchResults = (): JSX.Element[] =>
    searchResults.map((searchResult) => (
      <Dropdown.Item
        key={`search-result-${searchResult.place_id}`}
        onClick={() => pickSearchResult(searchResult)}
      >
        {searchResult.name} - {searchResult.rating} -{" "}
        {searchResult.formatted_address}
      </Dropdown.Item>
    ));

  return (
    <Container>
      <h1>New Client</h1>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        style={{ textAlign: "left" }}
      >
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Business Name*</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Business Name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && (
            <Form.Text className="error-label">{nameError}</Form.Text>
          )}
        </Form.Group>

        {selectedResult ? (
          <FlexRow>
            <p>
              {selectedResult.name} - {selectedResult.rating} -{" "}
              {selectedResult.formatted_address}
            </p>
            <Button
              type="button"
              variant="link"
              onClick={(e) => {
                e.preventDefault();
                setSelectedResult(null);
                setSearchActive(false);
              }}
            >
              <X />
            </Button>
          </FlexRow>
        ) : searchActive ? (
          <FlexRow style={{ justifyContent: "flex-start" }}>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Select Result
              </Dropdown.Toggle>

              <Dropdown.Menu>{renderSearchResults()}</Dropdown.Menu>
            </Dropdown>
            <Button
              type="button"
              variant="link"
              onClick={() => setSearchActive(false)}
            >
              <X />
            </Button>
          </FlexRow>
        ) : (
          <>
            <Form.Group className="mb-3" controlId="searchCity">
              <Form.Label>City*</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="City*"
                value={searchCity}
                onChange={(e) => setSearchCity(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="searchCity">
              <Form.Label>Search for Google Place</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Search Term"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              id="button-addon2"
              onClick={() => searchForPlaces()}
              disabled={!searchTerm || !searchCity}
            >
              Search
            </Button>
          </>
        )}
        {placeError && (
          <Form.Text className="error-label">{placeError}</Form.Text>
        )}

        <Form.Group controlId="logo" className="mb-3">
          <Form.Label>Logo</Form.Label>
          <InputGroup>
            <Form.Control
              disabled={logoImgUploading}
              type="file"
              value={logoImg}
              onChange={(event) => {
                const target = event.target as HTMLInputElement;
                setLogoImg(target.value);
                if (target && target.files && target.files[0]) {
                  onFileChange(target.files[0]);
                }
              }}
            />
            {logoImg && (
              <Button type="button" variant="secondary" onClick={clearFile}>
                <X />
              </Button>
            )}
            <Button type="button" variant="primary" onClick={handleUploadFile}>
              {logoImgUploading ? <Spinner animation="border" /> : "Upload"}
            </Button>
          </InputGroup>
          {logoImgError && (
            <Form.Text className="error-label">{logoImgError}</Form.Text>
          )}
        </Form.Group>

        {logoImgDataUri && (
          <LogoImg src={logoImgDataUri} alt="New business logo preview" />
        )}

        <Form.Group
          className="mb-3"
          controlId="email"
          style={{ marginTop: 20 }}
        >
          <Form.Label>Send Feedback Emails to*</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Send Feedback Emails to*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && (
            <Form.Text className="error-label">{emailError}</Form.Text>
          )}
        </Form.Group>

        <FlexRow>
          <Button
            variant="secondary"
            type="button"
            style={{ marginRight: 10 }}
            onClick={close}
          >
            Cancel
          </Button>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </FlexRow>
      </Form>
    </Container>
  );
}

export default NewClient;
