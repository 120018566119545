import { GooglePlacesResponse } from '../types';

/**
 * Fetches Details for Google Place by Place ID
 * @param placeId string
 * @returns Promise<GooglePlacesResponse | boolean>
 * From: https://developers.google.com/maps/documentation/places/web-service/details#maps_http_places_details_fields-txt
 */
export const fetchGoogleReviews = async (placeId: string): Promise<GooglePlacesResponse | boolean> => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/reviews/${placeId}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    return response.json();
  }

  return false;
}
