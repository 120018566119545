import styled, { css } from 'styled-components';

const flex = css`
  display: flex;
`;

const flexRow = css`
  ${flex}
  flex-direction: row;
`;

export const FlexRow = styled.div`
  ${flexRow}
  justify-content: flex-start;
  align-items: center;
`;

export const GoogleImg = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;